<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte Exigibles"
            class="tran"
          ></s-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-col sm="6" cols="6" lg="4" md="4">
              <s-select-definition
                :def="1215"
                v-model="objMounth"
                return-object
                label="Seleccione periodo"
              ></s-select-definition>
            </v-col>
            <v-row class="ml-3" align="end">
              <v-col sm="12" lg="8" md="8" class="pl-0">
                <v-btn
                  elevation="0"
                  :color="'success'"
                  small
                  @click="runView()"
                  class="ma-2"
                  >Buscar</v-btn
                >
                <v-btn
                  elevation="0"
                  style="text-transform: capitalize"
                  :color="'info'"
                  small
                  @click="downloadReportResumen()"
                  class="ma-2"
                  :disabled="disabledBtnDownload"
                  >Descargar Resumen</v-btn
                >
                <v-btn
                  elevation="0"
                  style="text-transform: capitalize"
                  :color="'error'"
                  small
                  @click="downloadBase()"
                  class="ma-2"
                  >Descargar Base</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model="currentItem">
        <v-tab href="#Dashboard"> Dashboard</v-tab>
        <v-tab href="#ReportPayable"> Reporte Exigibles</v-tab>
        <v-tab href="#BasePayable"> Base</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentItem">
        <v-tab-item :value="'Dashboard'">
          <clc-report-payable-dashboard
            :itemsDashboardInteres="interesDasboard"
            :itemsDashboard="capitalDasboard"
          ></clc-report-payable-dashboard>
        </v-tab-item>

        <v-tab-item :value="'ReportPayable'">
          <v-row>
            <v-spacer />
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Código Venta"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <gral-table
                :headers="headers"
                :items="items"
                :columnsNotNumber="['CodigoVenta', 'DNI', 'AnioVenta']"
                :propsdinamic="{ search, class: 'fixed-first-column' }"
              ></gral-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'BasePayable'">
          <v-card>
            <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
              <v-col cols="6" lg="3" md="3">
                <s-text label="Codigo de venta" v-model="CodVenta"></s-text>
              </v-col>
              <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                <v-btn
                  elevation="0"
                  :color="'success'"
                  small
                  @click="() => loadBase(this.CodVenta)"
                  >Buscar</v-btn
                >
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersBase"
                  :items="itemsBase"
                  :columnsNotNumber="[
                    'CodigoVenta',
                    'Dni',
                    'AnioVencimiento',
                    'Acta',
                    'P.Mx',
                    'AnioMesVemcimiento',
                    'AnioVenta',
                    'AnioPago',
                    'NroOperacion',
                  ]"
                  :propsdinamic="{ class: 'fixed-first-column' }"
                ></gral-table>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcReportPayableDashboard from "./ClcReportPayableDashboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    GralTable,
    ClcReportPayableDashboard,
  },
  data() {
    return {
      currentItem: "tab-Funciones",
      /**/

      /* */
      items: [],
      itemsBase: [],
      headers: [],
      headersBase: [],
      report: {},
      processing: false,
      messageProcessing: "",
      TypeDescription: 4,
      disabled: false,
      CodigoVenta: null,
      CodVenta: null,

      group: [],
      dataDashboard: [],
      itemsDashboardInteres: [],
      interesDasboard: [],
      capitalDasboard: [],
      search: "",
      objMounth: null,
      disabledBtnDownload: true,
    };
  },

  watch: {
    CodigoVenta() {
      if (this.CodigoVenta.length == 0) {
        this.disabled = false;
        this.CodigoVenta = null;
      } else {
        this.disabled = true;
        this.TypeDescription = 4;
      }
    },
  },
  methods: {
    rowSelected() {},
    runView() {
      this.loadDasboard();
      this.loadExigibles();
      this.disabledBtnDownload = false;
    },
    loadDasboard() {
      const report = {};

      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportPayableDasboard_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.capitalDasboard = resp.data.map((item) => ({
                name: item.exigible,
                y: item.capital,
              }));
              this.interesDasboard = resp.data.map((item) => ({
                name: item.exigible,
                y: item.interes,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    loadExigibles() {
      const report = {};

      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportPayableArt_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.items = resp.data;
              this.headers = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
                filterable: item === "CodigoVenta" ? true : false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    loadBase(filter = null) {
      const report = {};

      this.messageProcessing = "";
      report.QryParamsArray = "CodigoVenta,Period";
      report.QryParamsArrayValue =
        filter + "," + this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportPayableBase_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.itemsBase = resp.data;
              this.headersBase = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
                filterable: item === "CodigoVenta" ? true : false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },

    downloadBase() {
      this.report = {};
      this.messageProcessing = "";
      this.report.QryParamsArray = "CodigoVenta,Period";
      this.report.QryParamsArrayValue =
        "" + "," + this.objMounth.DedAbbreviation; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      this.report.QryNameProcedure = "ClcReportPayableBase_R";

      this.processing = true;
      _sQryConfigurationService
        .downloadexcel(this.report, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "Base Exigibles " + this.objMounth.DedDescription
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    downloadReportResumen() {
      this.report = {};
      this.messageProcessing = "";
      this.report.QryParamsArray = "Period";
      this.report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      this.report.QryNameProcedure = "ClcReportPayableArt_R";

      this.processing = true;
      _sQryConfigurationService
        .downloadexcel(this.report, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "Exigibles Resumen " + this.objMounth.DedDescription
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
.fixed-first-column thead tr,
.fixed-first-column tbody tr {
  position: relative;
}
.fixed-first-column thead th:first-child,
.fixed-first-column tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}
</style>
